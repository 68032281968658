import { useEffect, useState } from 'react';
import request from 'superagent';

const fallbackErrorMessage =
  'We encountered a problem while attempting to obtain the list of identity providers';

const parseError = (error: unknown) => {
  try {
    const { data } = JSON.parse((error as any).response.text);
    return data.message;
  } catch (err) {
    // Try to extract a message from an error object
    if (error instanceof Error) {
      const { message = 'An unknown error occurred.' } = error;
      return `${fallbackErrorMessage}: ${message}`;
    }

    return `${fallbackErrorMessage}.`;
  }
};

export interface UseSSOReturn {
  isFetching: boolean;
  providers: string[];
  error?: string;
}

/**
 * Hook to obtain the list of configured identity providers that the user can
 * authenticate with.
 */
const useSSO = ({
  isSsoAuthOnly = false,
  isLdapEnabled = false,
}: {
  isSsoAuthOnly?: boolean;
  isLdapEnabled?: boolean;
}): UseSSOReturn => {
  const [providers, setProviders] = useState<string[]>([]);
  const [isFetching, setIsFetching] = useState<boolean>(false);
  const [error, setError] = useState<string>();

  // Obtain the IDP list on mount
  useEffect(() => {
    if (!process.env.BROWSER) return;

    (async () => {
      try {
        setIsFetching(true);
        const response = await request.get(`${window.App.apiUrl}/sso/list`);

        if (!response.body.data.length && isSsoAuthOnly && !isLdapEnabled) {
          // Throw a specific error if there are no providers and other auth methods are disabled
          throw new Error(
            'The application has been configured for SSO authentication only, but no providers were returned. This is probably a mistake. You must configure at least one identity provider or LDAP when restricted authentication is enabled.',
          );
        }

        setProviders(response.body.data);
      } catch (err) {
        setError(parseError(err));
      } finally {
        setIsFetching(false);
      }
    })();
  }, []);

  return { providers, error, isFetching };
};

export default useSSO;
