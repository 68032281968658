import { useEffect, useMemo, useState } from 'react';
import { useIsClient } from 'usehooks-ts';

import useAuthPreferences from './useAuthPreferences';
import type { UseSSOReturn } from './useSSO';

export const AUTH_TYPES = ['default', 'ldap', 'sso'] as const;

export const [DEFAULT_AUTH, LDAP_AUTH, SSO_AUTH] = AUTH_TYPES;

export type AuthType = (typeof AUTH_TYPES)[number];

/**
 * Hook to manage state of the selected authentication type and persist to
 * local storage.
 */
const useAuthType = ({
  sso,
  isLdapEnabled = false,
  isSsoAuthOnly = false,
}: {
  sso: UseSSOReturn;
  isLdapEnabled?: boolean;
  isSsoAuthOnly?: boolean;
}): [AuthType, (value: AuthType) => void, AuthType[]] => {
  const isClient = useIsClient();

  // A guarded state setter is exposed to hook consumers
  const [authType, setAuthTypeInternal] = useState<AuthType>(() => {
    if (isSsoAuthOnly) return SSO_AUTH;
    if (isLdapEnabled) return LDAP_AUTH;

    return DEFAULT_AUTH;
  });

  /**
   * Enabled auth types are determined by the app config and become a stable
   * source of truth once its async dependencies have resolved.
   */
  const enabledAuthTypes = useMemo<AuthType[]>(() => {
    const authTypes: AuthType[] = [];

    // Wait for client render and SSO fetch to resolve before enabling anything.
    // Prevents early evaluation in consumers and makes the first state update
    // more stable for render.
    if (!isClient || sso.isFetching) return authTypes;

    // Enable default auth if not restricted to SSO.
    if (!isSsoAuthOnly) authTypes.push(DEFAULT_AUTH);

    // Enable SSO if providers are available or the fetch returned an error.
    if (sso.providers.length || sso.error) authTypes.push(SSO_AUTH);

    // LDAP is treated as SSO-adjacent, so it's always available if the flag is enabled.
    if (isLdapEnabled) authTypes.push(LDAP_AUTH);

    return authTypes;
  }, [
    isClient,
    isSsoAuthOnly,
    isLdapEnabled,
    sso.providers.length,
    sso.isFetching,
    sso.error,
  ]);

  /**
   * The last selected auth type is persisted to local storage as an assumed
   * user preference, so that it can be preselected on subsequent visits for
   * convenience.
   */
  const [authPreferences, , { setPreferredAuthType }] = useAuthPreferences();

  const isEnabled = (type: AuthType) => enabledAuthTypes.includes(type);

  /**
   * Updates the selected auth type if the new type is enabled and not the
   * currently selected type. Persists the selection to local storage so that
   * it can be restored later as the user's preferred auth type.
   */
  const setAuthType = (value: AuthType) => {
    if (value === authType || !isEnabled(value)) return;
    setAuthTypeInternal(value);
    setPreferredAuthType(value);
  };

  /**
   * Try to restore the auth type from local storage once the enabled types have
   * stabilized. This will usually only run once like a mount effect, but has to
   * depend on the enabled types, which includes an async dependency.
   */
  useEffect(() => {
    if (!authPreferences.authType) return;
    setAuthType(authPreferences.authType);
  }, [enabledAuthTypes]);

  return [authType, setAuthType, enabledAuthTypes];
};

export default useAuthType;
